* {
  transition: all ease 0.1s;
  font-family: "EB Garamond", serif;
  font-size: 1.1rem;
}

.black-outline {
  @apply border-2 bg-transparent text-gray-900 border-gray-900;
}

.black-outline-rect {
  @apply border-2 border-l-0 border-r-0 bg-transparent text-gray-900 border-gray-900;
}

.black-underline {
  @apply border-2 border-l-0 border-r-0 border-t-0 bg-transparent text-gray-900 border-gray-900;
}

.maroon-outline {
  @apply border-2 bg-transparent text-red-700 border-red-700;
}

.maroon-outline-rect {
  @apply border-2 border-l-0 border-r-0 bg-transparent text-red-700 border-red-700;
}

.maroon-underline {
  @apply border-2 border-l-0 border-r-0 border-t-0 bg-transparent text-red-700 border-red-700;
}

.gray-outline {
  @apply border-2 bg-transparent text-gray-500 border-gray-500;
}

.gray-outline-rect {
  @apply border-2 border-l-0 border-r-0 bg-transparent text-gray-500 border-gray-500;
}

.gray-underline {
  @apply border-2 border-l-0 border-r-0 border-t-0 bg-transparent text-gray-500 border-gray-500;
}

.paper-background {
  @apply text-gray-900;
  background: #edc;
}

.dark-paper-background {
  @apply text-gray-900;
  background: #dcb;
}

.display {
  font-family: "Playfair Display";
  font-weight: 900;
}

.center-flex {
  @apply flex justify-center items-center text-center;
}

h1 {
  @apply text-3xl font-bold;
  font-family: "Playfair Display", serif;
  font-weight: 900;
}
